import classNames from 'classnames';
import React from 'react';
import Card from '../Card';
import Container from '../Container';
import styles from './AboutMe.module.css';
import RenderContent from '../HTMLContent';
import Link from 'next/link';

const AboutMe = (props) => {
  const {
    aboutMeFields: { imagehomedesktop, imagehomemobile, homestext, buttonlink },
  } = props;
  return (
    <Container className='container px-4' component='section' isAnimated>
      <Card
        className={classNames(
          'px-8 py-14 lg:py-32 lg:px-24',
          styles.AboutMeGradient
        )}
        style={{
          background: `url(${imagehomedesktop?.sourceUrl}), var(--holo-bg)`,
          backgroundSize: 'cover',
        }}
      >
        <div className='flex flex-col w-full shrink-auto lg:shrink-0 lg:w-7/12 '>
          <img
            className='mx-auto mb-8 overflow-hidden rounded-full h-22 w-22 md:hidden'
            src={imagehomemobile?.sourceUrl}
            alt={imagehomemobile?.altText}
            width='384'
            height='512'
          />
          <h1 className='mb-4 text-5xl font-black uppercase md:text-6xl font-now'>
            About Me
          </h1>
          <RenderContent html={homestext} className='text-lg font-lato' />
          <button className='px-3 py-1 mx-auto mt-8 text-3xl font-black uppercase border-2 border-black rounded-none font-now md:mt-5 md:mr-auto md:ml-0 hover:bg-black hover:text-white'>
            <Link scroll={false} href={buttonlink?.url || '!#'} legacyBehavior>
              {buttonlink?.title}
            </Link>
          </button>
        </div>
      </Card>
    </Container>
  );
};

export default AboutMe;
